import React, { StrictMode, useReducer } from "react";
import Routers from "./router";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

import reducer from "./reducers";

import { appMiddleware } from "./middlewares/app";
import { apiMiddleware } from "./middlewares/core";

import { StateContext } from './context'
import appReducer from './reducer'

import './App.css';

const store = createStore(
  reducer,  
  applyMiddleware(appMiddleware, apiMiddleware, thunk)
);

const initialState = { 
  operaciones: null, 
  diccionario: null,  
};

export default function App() {
  const [ state, dispatch ] = useReducer(appReducer, initialState)
  return (
    <> 
    <StrictMode>
    <Provider store={store}>
      <StateContext.Provider value={{state, dispatch}}>
        <Routers />
      </StateContext.Provider>
    </Provider>
    </StrictMode>
    </>
  );
}
