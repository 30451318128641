import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, CssBaseline, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { makeStyles } from "mui-styles";

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LoadingComponent from "./LoadingComponent";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        '& > *': {
        //margin: theme.spacing(1),
        width: theme.spacing(25),
        //minHeight: theme.spacing(25),
        borderRadius: '15px !important',
        //padding: theme.spacing(1),
        },
        padding:10,
    },
    accordion: {
        minHeight: '130px !important;',
        paddingTop: '20px !important;',
        /*padding: '-1px -1px !important;',*/
        width: '100% !important',
    },
    accordionSummary: {
        marginBottom: '0px !important',
        padding: '0px 10px !important',
        minHeight: '90px !important',
    },    
    pie: {
        marginTop: 10,
    },
    diaMes: {
        fontFamily: 'Open Sans',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
        color: '#3465A4'
    },
    horario: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        color: '#000'
    },
    otrosTextos: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '20px',
    },
    enlacePlanificacion: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '20px',
        marginTop: '20px',
        marginLeft: 20,
    },
    fondoDiaActual:
    {
        backgroundColor: "#EBEBEB",
    },    
    bordeSinFirmar: {
        borderColor: "green",
        borderStyle: 'groove',
    },    
    bordeFirmado: {
        borderColor: "#FF0000",
        borderStyle: 'groove',
    },
    marginIconText: {
        display: 'block',
        marginTop: -28,
        marginLeft: 22
    }
}));

const Avisos = () => {

    const TIEMPO_DE_REFRESCO = 1000 * 5 * 5; //milisegundos * segundos * minutos 
    const [isLoading, setIsLoading] = useState(true)
    const [notas, setNotas] = useState([])    

    const fetchData = () => {
        (async () => {
            setIsLoading(true)
            const user = await JSON.parse(localStorage.getItem("user"));
            if(user){
                const URL_DATOS = process.env.REACT_APP_API_URL + `notasavisodestinatariosapps/getnotasavisodestinatariosinleer/${user.id}`
                console.log(URL_DATOS)
                const respData = await axios(URL_DATOS);
                console.log(respData.data)
                if (respData.data) {                                  
                    setNotas(respData.data); 
                }else{
                    setNotas([])
                }   
            }
            setIsLoading(false)
          })();
    }

    const actualizarNotas = () => {
        console.log("actualizarNotas")
        fetchData()
    }

    useEffect(() => {  
        fetchData()
        const interval = setInterval(() => {
            fetchData()
        }, TIEMPO_DE_REFRESCO);

        return () => clearInterval(interval)      
    }, [])

    const numeroNotas = notas.length
    const actualHeight = `{${60*numeroNotas}vh}`

    return (
    <React.Fragment>
        <CssBaseline />
        <Container maxWidth="xl">
        {isLoading && (
            <LoadingComponent />
        )}    
        {!isLoading && (
            <Box sx={{ bgcolor: '#FFF', height: actualHeight }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {notas && 
                            notas.map((nota, index) => (
                                <ComponenteAviso key={index} notaAviso={nota} actualizaNotas={actualizarNotas}/>
                            )
                        )}
                    </Grid>
                </Grid>
            </Box>
        )}

        </Container>
    </React.Fragment>   
    )
}
export default Avisos

const ComponenteAviso = ({notaAviso, actualizaNotas}) => {

    const [expanded, setExpanded] = useState('');
    const classes = useStyles();

    const handleSubmitPost = async (url, datos) => {
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + "notasavisodestinatariosapps/" + url;
        
        await axios
        .post(SERVER_URL, {
            headers: {
            "Content-Type": "application/json",
            Authorization: AuthStr,
            },
            datos
        })
        .then((response) => {  
            console.log(response)  
            actualizaNotas()        
            //actualizaNotas(true)            
        })
        .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
        });
            
    };
    
    const borrarNota = (nota) => { 
        console.log(nota)
        handleSubmitPost("setnotaleida", nota.notaAvisoDestinatarioId)

        //actualizaDatos(datosMano)
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const verFichero = (nota) => {
        const oDate = new Date();
        if(nota){
            if(nota.Ubicacion){
                window.open(nota.Ubicacion +'?qt='+oDate.getTime(), "_blank")
            }
        }
    }

    return(
        <div 
        className={ `${classes.root}` }
        id={`notaAviso-${notaAviso.idPlanificacion}-${notaAviso.idMano}`}
        >
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={ `${classes.accordion} ${notaAviso.firmado === true ? classes.bordeSinFirmar : classes.bordeFirmado} ` }>
                
                <AccordionSummary
                    expandIcon={expanded === 'panel1'?<RemoveIcon />:<AddIcon/>}
                    aria-controls="panel1a-content"
                    id1="panel1a-header"
                    className={classes.accordionSummary}
                    id={`draggable-accordion${notaAviso.notaAvisoId}`}
                    >
                    <div className={classes.cabecera2}>
                        <Typography className={classes.otrosTextos} color="textSecondary">
                            <ArrowRightIcon /><span className={classes.marginIconText}>{notaAviso.FechaCreacion}</span>
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary">
                            <ArrowRightIcon /><span className={classes.marginIconText}>{notaAviso.remitente.nombre}</span>
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary" >
                            <ArrowRightIcon /><span className={classes.marginIconText}>{notaAviso.Asunto}</span>
                        </Typography>
                    </div>
                </AccordionSummary>

                <AccordionDetails className="d-block" >
                    <div className={classes.pie}>

                        <Typography className={classes.horario} color="textSecondary" >
                            <ArrowRightIcon style={{ color: 'red' }}/><span className={classes.marginIconText}>{notaAviso.Mensaje}</span>
                        </Typography>
                        {notaAviso.ficheros && notaAviso.ficheros.length > 0 &&(
                            <Typography className={classes.horario} color="textSecondary" >
                                <ArrowRightIcon style={{ color: 'red' }}/><span className={classes.marginIconText}>Adjuntos</span>
                            </Typography>
                        )}
                        
                        {notaAviso.ficheros &&        
                            notaAviso.ficheros.map((fichero, index) => (
                                <Typography  key={index} className={classes.otrosTextos} style={{textAlign: 'center', marginTop: 10}} color="textSecondary" >
                                <Button startIcon={<PictureAsPdfIcon />} style={{ width: '75%', padding: 0}} variant="outlined" onClick={() => verFichero(fichero) } color="primary">{fichero.Nombre}</Button>
                                </Typography>
                            ))
                        }

                        <Typography className={classes.enlacePlanificacion} color="textSecondary">
                            <Button style={{width: '100%'}} variant="contained" onClick={() => borrarNota(notaAviso)} color="primary">Eliminar Aviso</Button>
                        </Typography>

                    </div>
                </AccordionDetails>

            </Accordion>
        </div>
    )
}