import React from "react";
import { Box, CircularProgress, Container, Grid, Typography } from "@mui/material";

const LoadingComponent = ({texto="...CARGANDO"}) => {

    return (
        <Box sx={{ display: 'flex' }}>
            <Container maxWidth="sm">
                <Grid style={{marginTop: '40px'}} container spacing={2}>
                    <Grid item xs={2}>
                        <CircularProgress />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography 
                            variant="h6" 
                            component="h6"
                            display="block" 
                            gutterBottom
                            style={{textAlign: 'left'}}
                        >
                            {texto}
                        </Typography>
                    </Grid>
                 </Grid>
            </Container>
        </Box>
    )
} 

export default LoadingComponent;