import React from "react";
import FormularioParte from "./FormularioParte";

const EditarParte = ({cerrarParte, datosMano}) => {
    
    return(
        <FormularioParte cerrarParte={cerrarParte} datosMano={datosMano} modo={"editar"} />
    )

}
export default EditarParte