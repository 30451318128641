import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export default function AlertaDialogo({titulo, abrir, cerrar, textoBoton2 = "Eliminar", texto = ""}) {
  const [open, setOpen] = React.useState(abrir);  
  const fullScreen = false   

  const handleCloseDisagree = () => {
    setOpen(false);
    cerrar(false)
  };

  const handleCloseAgree = () => {
    setOpen(false);
    cerrar(true)
  };

  return (
    <div>     
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseDisagree}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {titulo}
        </DialogTitle>   
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {texto}
          </DialogContentText>
        </DialogContent>     
        <DialogActions>
          <Button autoFocus onClick={handleCloseDisagree}>
            Cancelar
          </Button>
          <Button onClick={handleCloseAgree} autoFocus>
            {textoBoton2}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}