import React, { useState } from "react";
import axios from "axios";

import {Chip, MenuItem, Dialog, DialogActions, DialogTitle, Button } from "@mui/material";
import AlertaMensaje from "./AlertaMensaje";


const FormFieldFiles = ({ propiedades }) => { 
    //console.log(propiedades)
    const [open, setOpen] = React.useState(false);
    const [ficheroAEliminar, setFicheroAEliminar] = React.useState({});
    const [openAlert,setOpenAlert] = useState({
        open : false,
        tipoMensaje : "success",
        mensaje : ""
      });  

    const handleDelete = (idFichero) => {  
        setFicheroAEliminar({
            idFichero: idFichero.Id,
            tabla: propiedades.tabla,
            modelo: propiedades.modelo,
            carpeta: propiedades.carpeta,
        })        
        setOpen(true);
    }    

    const handleCloseAlert = (event, reason)=>{
        if(reason === "clickaway"){
          return
        }
        setOpenAlert({...openAlert,open:false});
      }

    const handleCloseEliminar = () => {      
        const fetchData = async () => {     
            let datos = {}
            const user = JSON.parse(localStorage.getItem("user"));
            const AuthStr = "Bearer ".concat(user.access_token);
            const SERVER_URL = `${process.env.REACT_APP_API_URL}userapps/eliminaficheroadjunto`;
            
            datos.idFichero = ficheroAEliminar.idFichero
            datos.carpeta = ficheroAEliminar.carpeta
            datos.modelo = ficheroAEliminar.modelo
            datos.username=user.username
            datos.userid=user.id

            //console.log(datos)

            const options = {
              method: 'post',
              url: SERVER_URL,
              data: datos,
              headers: { 'content-type': 'multipart/form-data' }                
            };

            axios(options)
            .then((response) => {  
                if(!isNaN(response.data)){
                  setOpenAlert({
                    open : true,
                    tipoMensaje : "success",
                    mensaje : "Fichero eliminado correctamente"
                  })
                  setTimeout(function () {
                      propiedades.cerrarParte() 
                  }, 1000);
                }else{
                  setOpenAlert({
                    open : true,
                    tipoMensaje : "error",
                    mensaje : "Error al eliminar el fichero"
                  })
                }
                return;           
            })
            .catch((error) => {
              console.log(error)
              if (error.response) {
                  // La respuesta fue hecha y el servidor respondió con un código de estado
                  // que esta fuera del rango de 2xx
                  //alert(3)
                  alert(error.response.data);
                  alert(error.response.status);
                  alert(error.response.headers);
                } else if (error.request) {
                  // La petición fue hecha pero no se recibió respuesta
                  // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
                  // http.ClientRequest en node.js
                  //alert(4)
                  alert(JSON.stringify(error.request));
                } else {
                  //alert(5)
                  // Algo paso al preparar la petición que lanzo un Error
                  alert('Error', error.message);
                  console.log(5)
                  // Algo paso al preparar la petición que lanzo un Error
                  console.log('Error', error.message);
                }
          });
        }
        fetchData();
        setOpen(false);
      };
  
    const handleCloseCancelar = () => {      
        setOpen(false);
    };

    const handleClick = (fichero) => {
        window.open(fichero.Ubicacion, "_blank")
    };

  return (  
    <>      
    {openAlert.open && (
        <AlertaMensaje
            mensaje={openAlert.mensaje} 
            isOpen={openAlert.open} 
            tipoMensaje={openAlert.tipoMensaje}
            cerrar={handleCloseAlert}
        />       
    )}
    <div className="col-12  mt-2 mb-2  ">     
    {/*<Grid item xs={propiedades.xs} className="" >*/}
    { 
        propiedades.ficheros && 
        propiedades.ficheros.map((option, index) => ( 
        <MenuItem key={index} value={option.Id} className="no-gutters float-left">
            <div className="col mt-1">                 
                <Chip 
                    key={index}
                    className="m-1"
                    variant="outlined" 
                    color="primary" 
                    size="small" 
                    onDelete={() => handleDelete(option)} 
                    onClick={() => handleClick(option)}
                    label={option.Nombre}
                />
            </div>
        </MenuItem>  
    ))}         
    {/*</Grid> */} 
    </div>   
    <div className="col-12  mt-2 mb-2  ">
        <Dialog
            open={open}
            //TransitionComponent={Transition}
            keepMounted
            onClose={() => handleCloseCancelar()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"¿Desea eliminar el fichero?"}</DialogTitle>
                        
            <DialogActions>
                <Button onClick={handleCloseEliminar} color="primary">
                    Eliminar
                </Button>
                <Button onClick={handleCloseCancelar} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    </div> 
    </>           
    )
}
export default FormFieldFiles;