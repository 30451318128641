export default function diccionarioReducer (state, action){
    switch(action.type){
        case 'SET_DICCIONARIO':
            return {
                ...state,
                buques: action.payload.buques,
                clientes: action.payload.clientes,
                contactos: action.payload.contactos,
                canales: action.payload.canalSolicitud,
                muelles: action.payload.localizaciones,
                operativas: action.payload.tiposOperativas,            
                servicios: action.payload.servicios,  
                categorias: action.payload.categorias,    
                puertos: action.payload.puertos, 
                modalidades: action.payload.modalidades, 
              };

        default: 
            return state;
    }
}