import React, { useEffect, useState } from "react";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Autocomplete, Box, Chip, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem } from "@mui/material";
import { styled } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Typography from '@mui/material/Typography';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import FormFieldFiles from './FormFieldFiles'

import axios from "axios";
import LoadingComponent from "./LoadingComponent";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormularioPlanificacion = ({cerrarParte, datosMano, modo}) => {
    
    const [open, setOpen] = useState(false);
    const [valores, setValores] = useState([]);
    const [localizaciones, setLocalizaciones] = useState([])
    const [buques, setBuques] = useState([])
    const [jornadas, setJornadas] = useState([])
    const [supervisores, setSupervisores] = useState([])
    const [empleados, setEmpleados] = useState([])
    const [openErrores, setOpenErrores] = useState({mostrar: false, mensaje: ""});
    const [ficheroAEliminar, setFicheroAEliminar] = useState({});
    const [ficheros, setFicheros] = useState([]);
    const [nombresFicheros, setNombresFicheros] = React.useState([]);
    const [datos, setDatos] = useState(
        {
            fechaOperativa: '', 
            horaInicio: "00:00", 
            clientes: [],
            localizacion: '',
            buque: '',     
            operativa: '',       
            jornada: '',
            supervisor: '',
            empleados: [],      
            descripcion: '',      
            ficheros: []
    });

    const [loading, setLoading] = useState(false);
    
    useEffect(() => { 
        let active = true;

        (async () => {   
            const respClientes = await axios(process.env.REACT_APP_API_URL + "userapps/listadoclientes");   
            const respLocalizaciones = await axios(process.env.REACT_APP_API_URL + "userapps/listadolocalizaciones"); 
            const respBuques = await axios(process.env.REACT_APP_API_URL + "userapps/listadobuques");   
            const respJornadas = await axios(process.env.REACT_APP_API_URL + "userapps/listadojornadas"); 
            const respEmpleados = await axios(process.env.REACT_APP_API_URL + "userapps/listadoempleadosoperativas"); 
            const respSupervisores = await axios(process.env.REACT_APP_API_URL + "userapps/listadosupervisoresoperativas"); 

            if (active) {                

                if(respSupervisores.data.length > 0 ){
                    setSupervisores(Object.keys(respSupervisores.data).map((key) => respSupervisores.data[key]))
                } else {                     
                      const sinDatos = [{ 
                          id: -1,                          
                          nombre: "Sin datos"
                      }]
                      setSupervisores(sinDatos)
                }

                if(respEmpleados.data.length > 0 ){
                    setEmpleados(Object.keys(respEmpleados.data).map((key) => respEmpleados.data[key]))
                } else {                     
                      const sinDatos = [{ 
                          id: -1,                          
                          nombre: "Sin datos"
                      }]
                      setEmpleados(sinDatos)
                }

                if(respJornadas.data.length > 0 ){
                    setJornadas(Object.keys(respJornadas.data).map((key) => respJornadas.data[key]))
                } else {                     
                      const sinDatos = [{ 
                          Id: -1,                          
                          Nombre: "Sin datos"
                      }]
                      setJornadas(sinDatos)
                }

                if(respBuques.data.length > 0 ){
                    setBuques(Object.keys(respBuques.data).map((key) => respBuques.data[key]))
                } else {                     
                      const sinDatos = [{ 
                          Id: -1,                          
                          Nombre: "Sin datos"
                      }]
                      setBuques(sinDatos)
                }

                if(respLocalizaciones.data.length > 0 ){
                    setLocalizaciones(Object.keys(respLocalizaciones.data).map((key) => respLocalizaciones.data[key]))
                } else {                     
                      const sinDatos = [{ 
                          Id: -1,
                          Codigo: -1,
                          Etiqueta: "Sin datos"
                      }]
                      setLocalizaciones(sinDatos)
                }

                if(respClientes.data.length > 0 ){
                  setValores(Object.keys(respClientes.data).map((key) => respClientes.data[key]))
                } else {
                    const etiqueta = 'Id';
                    const sinDatos = [{ 
                        Id: -1,
                        [etiqueta]: "Sin datos"
                    }]
                    setValores(sinDatos)
                }
            }
          }
        )();
    
          return () => {
            active = false;
          };
    }, [])

    const guardarParte = async () => {
        let mensajeError = "";
        if(datos.fechaOperativa === "") 
            mensajeError += "Debe introducir una fecha de operativa.\n"

        if(datos.clientes.length === 0) 
            mensajeError += "Debe seleccionar al menos un cliente.\n"        

        if(mensajeError.length > 0){
            setOpenErrores({mostrar: true, mensaje: mensajeError})
            return;
        }
        
        datos.ficheros = ficheros
        datos.nombresFicheros = nombresFicheros
       
        const user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);

        datos.userid = user.id

        if(modo === "crear")
        {  
            setLoading(true)
            const SERVER_URL = `${process.env.REACT_APP_API_URL}planificacionesapps`;
            const options = {
                method: 'post',
                url: SERVER_URL,
                data: datos,
                headers: { 'content-type': 'multipart/form-data' }                
            };

            axios(options)
              .then((response) => { 
                console.log(response.data)
                if(!isNaN(response.data)){   
                    cerrarParte() 
                }else{                   
                    //alert(response)
                }            
              })
              .catch(function (error) {

                if (error.response) {
                    // La respuesta fue hecha y el servidor respondió con un código de estado
                    // que esta fuera del rango de 2xx
                    //alert(3)
                    alert(error.response.data);
                    alert(error.response.status);
                    alert(error.response.headers);
                  } else if (error.request) {
                    // La petición fue hecha pero no se recibió respuesta
                    // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
                    // http.ClientRequest en node.js
                    alert(4)
                    
                    alert(JSON.stringify(error.request));
                  } else {
                    //alert(5)
                    // Algo paso al preparar la petición que lanzo un Error
                    alert('Error', error.message);
                    console.log(5)
                    // Algo paso al preparar la petición que lanzo un Error
                    console.log('Error', error.message);
                  }
                  //alert(JSON.stringify(error.config));
                  //console.log(JSON.stringify(error.config));
                
              })
              .finally(function () {
                // siempre sera ejecutado
                setLoading(false)
              });
        }
    }    

    const handleSelectedFile = ({target}) => {
        for (let i=0; i<target.files.length; i++){  
            const fileReader = new FileReader();          
            fileReader.readAsDataURL(target.files[0]);
            fileReader.onload = (e) => {
              setFicheros(ficheros => [...ficheros, e.target.result] )
              setNombresFicheros(nombresFicheros => [...nombresFicheros, target.files[i].name] )                                  
              
            };
          } 
    };

    const handleDelete = (index) => {  
        let elementos = JSON.parse(JSON.stringify(ficheros)); 
        let elementos2 = JSON.parse(JSON.stringify(nombresFicheros));       
        elementos.splice(index, 1)
        elementos2.splice(index, 1)
        setFicheros(elementos)
        setNombresFicheros(elementos2) 
    }  

    const handleCloseEliminar = () => {      
        const ficherosFiltrados = datos.ficheros.filter((file) => file.name !== ficheroAEliminar.name);
        setDatos({...datos, ficheros: ficherosFiltrados});
        setOpen(false);
      };

    const handleCloseCancelar = () => {      
        setOpen(false);
    };

    const handleCloseCancelarErrores = () => {      
        setOpenErrores({mostrar: false, mensaje: ''})
    };


    const element = {
            required: false,
            label: "",
            name: "ficherosremotos",
            type: "files", 
            ficheros: datos.ficheros,    
            tabla: "mtms_operaciones_gesport_adjuntos",
            modelo: "api\\modules\\v1\\models\\operaciones\\OperacionesGesportAdjuntos",
            carpeta: "operaciones/partesgesport",
            xs:4,
            cerrarParte: cerrarParte
    }
    
    return (
        <>
        {loading && (
             <LoadingComponent texto={"...CREANDO PLANIFICACIÓN"} />
        )}
        {!loading && (
        <Box>
            <Container maxWidth="sm">
                
                <Grid style={{marginTop: '0px'}} container spacing={2}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography 
                            variant="caption" 
                            display="block" 
                            gutterBottom
                            style={{textAlign: 'right'}}
                        >
                            CREAR PLANIFICACIÓN
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{paddingTop: '0px'}}>
                        <hr style={{
                            color: "#000",
                            backgroundColor: "#000",
                            height: 1
                        }}/>
                    </Grid>
                </Grid>

                <Grid style={{marginTop: '20px'}} container spacing={2}>
                    {/* FECHA OPERATIVA */}
                    <Grid item xs={6}>
                        <TextField
                            label="Fecha operativa"
                            id="fechaOperativa"                            
                            size="small"
                            type="date"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            value={datos.fechaOperativa}
                            onChange={(event) => {
                                setDatos({...datos, fechaOperativa: event.target.value});
                            }}
                        />                
                    </Grid>
                    {/* HORA INICIO */}
                    <Grid item xs={6}>
                        <TextField
                            label="Hora Inicio"
                            id="horaInicio"                            
                            size="small"
                            type="time"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={datos.horaInicio}
                            onChange={(event) => {
                                setDatos({...datos, horaInicio: event.target.value});
                            }}
                        />                
                    </Grid>
                    {/* CLIENTES */}
                    <Grid item xs={12}>
                        <Autocomplete
                            value={datos.clientes}
                            onChange={(event, newValue) => {
                                setDatos(() => ({...datos, clientes: newValue}));
                            }}
                            multiple
                            id="clientes"
                            options={valores}
                            disableCloseOnSelect
                            size="small"
                            getOptionLabel={(option) => option.Razon_Social}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.Razon_Social}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value) => 
                                option.Id === value.Id
                            }
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Clientes" placeholder="" 
                                required={true}
                                />
                            )}
                        />
                    </Grid>
                    {/* LOCALIZACIÓN */}
                    <Grid item xs={6}>
                        <Autocomplete
                            value={datos.localizacion}
                            onChange={(event, newValue) => {
                                setDatos(() => ({...datos, localizacion: newValue}));
                            }}                            
                            id="localizacion"
                            options={localizaciones}
                            disableCloseOnSelect
                            size="small"
                            getOptionLabel={(option) => option.Etiqueta || ""}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.Etiqueta}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value) => 
                                option.Id === value.Id
                            }
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Localización" placeholder="" />
                            )}
                        />                
                    </Grid>
                    {/* BUQUE */}
                    <Grid item xs={6}>
                        <Autocomplete
                            value={datos.buque}
                            onChange={(event, newValue) => {
                                setDatos(() => ({...datos, buque: newValue}));
                            }}                            
                            id="buque"
                            options={buques}
                            disableCloseOnSelect
                            size="small"
                            getOptionLabel={(option) => option.Nombre || ""}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.Nombre}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value) => 
                                option.Id === value.Id
                            }
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Buque" placeholder="" />
                            )}
                        />                
                    </Grid>  
                    {/* OPERATIVA */}
                    <Grid item xs={12}>
                        <TextField
                            id="operativa"
                            label="Operativa"                            
                            style={{ width: '100%' }}
                            size="small"
                            //defaultValue="Default Value"
                            value={datos.operativa}
                            onChange={(event) => {
                                setDatos({...datos, operativa: event.target.value});
                            }}
                        />
                    </Grid>                   
                    {/* JORNADA */}
                    <Grid item xs={12}>
                        <Autocomplete
                            value={datos.jornada}
                            onChange={(event, newValue) => {
                                setDatos(() => ({...datos, jornada: newValue}));
                            }}                            
                            id="jornada"
                            options={jornadas}
                            disableCloseOnSelect
                            size="small"
                            getOptionLabel={(option) => option.Etiqueta || ""}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.Etiqueta}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value) => 
                                option.Id === value.Id
                            }
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Jornada" placeholder="" />
                            )}
                        />                
                    </Grid>
                    {/* SUPERVISOR */}
                    <Grid item xs={12}>
                        <Autocomplete
                            value={datos.supervisor}
                            onChange={(event, newValue) => {
                                setDatos(() => ({...datos, supervisor: newValue}));
                            }}                            
                            id="supervisor"
                            options={supervisores}
                            disableCloseOnSelect
                            size="small"
                            getOptionLabel={(option) => option.nombre || ""}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.nombre}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value) => 
                                option.id === value.id
                            }
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Supervisor" placeholder="" />
                            )}
                        />                
                    </Grid>
                    {/* EMPLEADOS */}
                    <Grid item xs={12}>
                        <Autocomplete
                            value={datos.empleados}
                            onChange={(event, newValue) => {
                                setDatos({...datos, empleados: newValue});
                            }}
                            multiple
                            id="empleados"
                            options={empleados}
                            disableCloseOnSelect
                            size="small"
                            getOptionLabel={(option) => option.nombre}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.nombre}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Empleados" placeholder="" />
                            )}
                        />

                    </Grid>
                    {/* DESCRIPCIÓN */}
                    <Grid item xs={12}>
                        <TextField
                            id="descripcion"
                            label="Descripción"
                            multiline
                            rows={3}
                            style={{ width: '100%' }}
                            //defaultValue="Default Value"
                            value={datos.descripcion}
                            onChange={(event) => {
                                setDatos({...datos, descripcion: event.target.value});
                            }}
                        />
                    </Grid>
                    

                    {/* FICHEROS REMOTOS */}
                    <FormFieldFiles
                        propiedades={element}
                        //control={control} 
                        ficheros={handleSelectedFile}
                    />
                   
                    {/* ADJUNTAR FICHERO */}
                    <Grid item xs={12} className="">
                        <Button
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            fullWidth
                           component="label"
                        >
                            <VisuallyHiddenInput multiple type="file" accept="image/*" onChange={handleSelectedFile} />
                        </Button>

                    </Grid>

                    {/* FICHEROS ADJUNTOS */}
                    <Grid item xs={12}>
                    {nombresFicheros && 
                     nombresFicheros.map((option, index) => (
                        <MenuItem key={index} value={option} className="no-gutters float-left">
                            <Grid item xs={12}>
                                <Chip 
                                    key={index}
                                    className="m-1"
                                    variant="outlined" 
                                    color="primary" 
                                    size="small" 
                                    onDelete={() => handleDelete(index)} 
                                    //onClick={() => handleClick(option)}
                                    label={option}
                                />
                            </Grid>
                        </MenuItem>
                     ))}
                    </Grid>
                </Grid>

                <Grid style={{marginTop: '0px'}} container spacing={4}>
                    <Grid item xs={12}>
                        {/* DIALOGO ELIMINAR FICHEROS ADJUNTOS */}
                        <Dialog 
                            open={open}
                            //TransitionComponent={Transition}
                            keepMounted
                            onClose={() => handleCloseCancelar()}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{"¿Desea eliminar el fichero?"}</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleCloseEliminar} color="primary">
                                    Eliminar
                                </Button>
                                <Button onClick={handleCloseCancelar} color="primary">
                                    Cancelar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>

                <Grid style={{marginBottom: '80px'}} container spacing={4}>
                    <Grid item xs={6}>
                        <Button 
                            variant="contained" 
                            startIcon={<SaveIcon />}
                            fullWidth
                            onClick={() => guardarParte() } 
                            color="primary"></Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            variant="contained" 
                            startIcon={<CloseIcon />}
                            fullWidth
                            onClick={() => cerrarParte() } 
                            color="primary">
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {/* DIALOGO MOSTAR ERRORES */}
                        <Dialog 
                            open={openErrores.mostrar}
                            //TransitionComponent={Transition}
                            keepMounted
                            onClose={() => handleCloseCancelar()}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{"ERROR"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" style={{whiteSpace: 'pre-line'}}>
                                    {openErrores.mensaje}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseCancelarErrores} color="primary">
                                    Cerrar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        )}        
        </>
    )
}
export default FormularioPlanificacion

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});




/*
import DeleteIcon from '@mui/icons-material/Delete';
import SignatureCanvas from 'react-signature-canvas'

const sigCanvas = useRef();
//const [value, setValue] = React.useState("");
    useEffect(() => {
        if (value) {
            sigCanvas.current.fromDataURL(value);
        }
    }, [value]);
                if(modo==="editar"){
                    const respParteGesport = await axios(process.env.REACT_APP_API_URL + "operacionesapps/parte/" + datosMano.idParte);      
                    
                    setDatos({
                        fechaOperativa: respParteGesport.data.horaInicio, 
                        horaFin: respParteGesport.data.horaFin, 
                        empleados: respParteGesport.data.empleados, 
                        clientes: [],
                        toneladas: parseFloat(respParteGesport.data.Toneladas),
                        movimientos: parseFloat(respParteGesport.data.Movimientos),
                        observaciones: respParteGesport.data.Observaciones,
                        ficheros: respParteGesport.data.ficheros
                    });
                    
                    if (Object.keys(sigCanvas) && respParteGesport.data.FirmaCliente) {
                        sigCanvas.current.fromDataURL(respParteGesport.data.FirmaCliente);
                    }
                
                }
                

        if(modo === "editar")
        {
            setLoading(true)
            const SERVER_URL = `${process.env.REACT_APP_API_URL}operacionesapps/editarpartegesportempleados`;
            datos.idParte = datosMano.idParte
            const options = {
                method: 'post',
                url: SERVER_URL,
                data: datos,
                headers: { 'content-type': 'multipart/form-data' }                
            };
            
            axios(options)
            .then((response) => {  
                if(!isNaN(response.data)){   
                    cerrarParte() 
                }else{
                }            
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    alert(error.response.data);
                    alert(error.response.status);
                    alert(error.response.headers);
                  } else if (error.request) {
                    alert(JSON.stringify(error.request));
                  } else {
                    alert('Error', error.message);
                    console.log(5)
                    console.log('Error', error.message);
                  }
            })
            .finally(function () {
                // siempre sera ejecutado
                setLoading(false)
            });
        }
        */