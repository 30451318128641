import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";

//layouts
import Main from "../containers/layouts/Main";
import MainProtected from "../containers/layouts/MainProtected";

// views
import Login from "../containers/views/Login";
import RecuperarPassword from "../containers/views/RecuperarPassword";
import ProtectedRoute from "../components/ProtectedRoute";

export default connect(({ reducerApi }) => ({ reducerApi }), {
})((props) => {   
    const { isAuthUser } = props.reducerApi;

    return(   
        <BrowserRouter>
          <Routes>

          {isAuthUser && (
            <>
            <Route element={<MainProtected/>}>             
              
              <Route 
                path="/" 
                element={
                  <ProtectedRoute isAuthenticated={isAuthUser}></ProtectedRoute>} 
              /> 
              <Route path='*' element={<Navigate to='/' />} />
            </Route>
            </>
          )}

          {!isAuthUser && (
            <>
            <Route path="/login" element={<Login />} />
            <Route element={<Main/>}>
                <Route path="/" element={<Login />} />                
                <Route path="/recuperarpassword" element={<RecuperarPassword />} />
            </Route>
            </>
          )}
           
          </Routes>
    </BrowserRouter>
  )
});