import axios from "axios";
import { API_REQUEST, apiError, apiSuccess, apiRecoveryPassword } from "../actions/api";
import { setLoader } from "../actions/ui";

export const apiMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === API_REQUEST) {
    dispatch(setLoader({ state: true }));
    const { url, method, data } = action.meta;
    
    const options = {
        method: method,        
        url: url,
        data: data,
        headers: { 'content-type': 'multipart/form-data'}        
    };

    axios(options)
    .then(({ data }) => {
     if(data){
      if(data.codigo){
        if(data.codigo === "error"){
          dispatch(
            apiError({
              error:   data.mensaje,
              mensaje: {message: data.mensaje},
            })
          ); 
        }

        if(data.codigo === "success"){
          dispatch(
            apiRecoveryPassword({
              mensaje:  data.mensaje,
              error:    null,
            })
          ); 
        }
      }
    
        
      if (data.access_token) {
        dispatch(apiSuccess({ response: data }));
      }

      if (data.password) {
        dispatch(
          apiError({
            mensaje: {message: "Usuario o Contraseña incorrectos."},
            error: "Usuario o Contraseña incorrectos.",
          })
        );
      }
     }

    })
    .catch((error) => {
      //console.log(error)
      //console.log(error.response)
      //alert(error);
      //alert(error.response);
      dispatch(apiError({ 
        mensaje: {message: error.response?.data?.message},
        error: error.response?.data?.message 
      }
        ));
    })
    .finally(function() {

    });
  }
};
