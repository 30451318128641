import React, { useState, useEffect, useRef } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { Avatar, CssBaseline, Grid, Typography } from "@mui/material";
import Operaciones from "../../components/Operaciones";
import Partes from "../../components/Partes";
import Avisos from "../../components/Avisos";
import Perfil from "../../components/Perfil";

const MainProtected = (props) => { 
  const [value, setValue] = useState(0);
  const ref = useRef(null);
    
  useEffect(()=>{    
    if(props){
      if(props.reducerApi){
        if(props.reducerApi.user){
          var dateMax = new Date(props.reducerApi.user.expireAt * 1000);
          var dateNow = new Date();

          if(dateNow >= dateMax) {
            props.logout()
            window.location.href = '/';
          }          
        }
      }
    }
  },[props])

  useEffect(()=>{
    ref.current.ownerDocument.body.scrollTop = 0;
    if(value === 4) handleLogOut();
  },[value])

  const handleLogOut = (e) => {
    props.logout()
    window.location.href = '/';
  }

  return (
    <Box sx={{ pb: 7 }} ref={ref} >
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Avatar sx={{ width: 32, height: 32, m: 1, bgcolor: 'secondary.main' }}>
            <img src="/logo192.png" alt="Logo" width="32" height="32" />
          </Avatar>
        </Grid>
      </Grid>
      <CssBaseline />
      {value === 0 && <Operaciones />}
      {value === 1 && <Partes />}
      {value === 2 && <Avisos />}
      {value === 3 && <Perfil props={props}/>}
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Operaciones" />
          <BottomNavigationAction label="Partes" />
          <BottomNavigationAction label="Avisos" />
          <BottomNavigationAction label="Perfil" />
        </BottomNavigation>
      </Paper>
    </Box>
  )
}
//export default MainProtected;
export default connect(({ reducerApi }) => ({ reducerApi }), {
  logout,
})(MainProtected);
