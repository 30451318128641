import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, Typography, CircularProgress } from "@mui/material";
import { makeStyles } from "mui-styles";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import PostAddIcon from '@mui/icons-material/PostAdd';
import RemoveIcon from '@mui/icons-material/Remove';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import CrearParte from "./CrearParte";
import LoadingComponent from "./LoadingComponent";
import CrearPlanificacion from "./CrearPlanificacion";
import AlertaDialogo from "./AlertaDialogo";
import AlertaMensaje from "./AlertaMensaje";

const INTERVAL_TIME = 1000 * 60 * 100; // 5 Minutos 1000 * 60 * 5

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        '& > *': {
        //margin: theme.spacing(1),
        width: theme.spacing(25),
        //minHeight: theme.spacing(25),
        borderRadius: '15px !important',
        //padding: theme.spacing(1),
        },
        padding:10,
    },
    accordion: {
        minHeight: '130px !important;',
        paddingTop: '20px !important;',
        /*padding: '-1px -1px !important;',*/
        width: '100% !important',
    },
    accordionSummary: {
        marginBottom: '0px !important',
        padding: '0px 10px !important',
        minHeight: '90px !important',
    },    
    pie: {
        marginTop: 10,
    },
    diaMes: {
        fontFamily: 'Open Sans',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
        color: '#3465A4'
    },
    horario: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        color: '#000'
    },
    otrosTextos: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '20px',
    },
    enlacePlanificacion: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '20px',
        marginTop: '20px',
        marginLeft: 20,
    },
    fondoDiaActual:
    {
        backgroundColor: "#EBEBEB",
    },    
    bordeSinPartes: {
        borderColor: "green",
        borderStyle: 'groove',
    },    
    bordeConPartes: {
        borderColor: "#FF0000",
        borderStyle: 'groove',
    },
    marginIconText: {
        display: 'block',
        marginTop: -28,
        marginLeft: 22
    }
}));

const Operaciones = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [datosManos, setDatosManos] = useState([]); 
    const [counter, setCounter] = useState(new Date());
    const diaActual = useRef({fechInicial: '', anioActual: '', mesActual: '', diaActual: '', fechaMostrar: ''});
    const usuarioActual = useRef({});
    const [crearParte, setCrearParte] = useState({idPlanificacion: -1, cogigo: '', idOperacion: null, idMano: null})
    const [crearPlanificacion, setCrearPlanificacion] = useState(false);

    let today = '';

    const changeDataWeek = (diasSumar = 0) => {
        let curr = new Date(); 
        if(diaActual.current.fechInicial)
            curr = new Date(diaActual.current.fechInicial);
        curr.setDate(curr.getDate() + diasSumar);

        let first = curr.getDate();
        const anioActual = curr.getFullYear();
        const mesActual = curr.getMonth();
        
        today = new Date(curr.setDate(first)).toISOString().split('T')[0];
        const fechaMostrar = `${first}/${mesActual+1}/${anioActual}`

        diaActual.current = {fechInicial: today, anio: anioActual, mes: mesActual, dia: first, fechaMostrar: fechaMostrar}
    }

    const getData = () => {
        (async () => {
            const user = await JSON.parse(localStorage.getItem("user"));
            if(user){
                usuarioActual.current = user;
                setIsLoading(true)
                const URL_DATOS = process.env.REACT_APP_API_URL + 'planificacionesapps'                 
                const params = {
                    id: user.id,
                    fechainicial: diaActual.current.fechInicial
                }
               
                axios.get(URL_DATOS, {params : params})
                .then(({ data }) => {
                    setDatosManos(data)
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                 })
                 .finally(function() {
                    setIsLoading(false)
                 });
            }
            
          })();
    }

    useEffect(() => {
        changeDataWeek();
        getData();
        setCounter(new Date());
    },[])

    useEffect(() => {
        let intervalId;
        intervalId = setInterval(() => {
            getData();
            setCounter(new Date());
          }, INTERVAL_TIME)
        
        return () => clearInterval(intervalId);
    }, [counter])

    const irADiaAnterior = () => {
        changeDataWeek(-1)
        getData();
        setCounter(new Date());
    }

    const irADiaSiguiente = () => {
        changeDataWeek(+1)
        getData();
        setCounter(new Date());
    }

    const irADiaActual = () => {
        //window.location.reload();
        changeDataWeek();
        getData();
        setCounter(new Date());
    }

    const actualizaDatos = async (datosPlanificacion) => {
        setCrearParte(datosPlanificacion)
    }

    const cerrarParte = () => {
        setCrearParte({idPlanificacion: -1, cogigo: '', idOperacion: null, idMano: null})
        irADiaActual()
    }

    const cerrarPlanificacion = () => {
        setCrearPlanificacion(false)
        irADiaActual()
    }

    const handleCrearPlanificacion = () => {        
        setCrearPlanificacion(true)
    }

    const numeroManos = datosManos.length
    const actualHeight = `{${60*numeroManos}vh}`
    const permisoUsuario = usuarioActual.current?.permisosApp

    return (
    <React.Fragment>
      <CssBaseline />

      <Container maxWidth="xl">
        {isLoading && (
            <LoadingComponent />
        )}

        {!isLoading && (
            <Box sx={{ bgcolor: '#FFF', height: actualHeight }}>
                { (crearParte.idPlanificacion < 0 && crearPlanificacion === false) && (
                <>

                <Grid container spacing={1}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="previous" onClick={() => irADiaAnterior() }>
                            <NavigateBeforeIcon titleAccess="Semana anterior"/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={4} style={{textAlign:'center', marginTop: '5px'}}>
                        {diaActual.current.fechaMostrar}
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="Semana siguiente" onClick={() => irADiaSiguiente() }>
                            <NavigateNextIcon titleAccess="Semana siguiente"/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}>
                        {(permisoUsuario === "SUPERVISOR" && 
                        <IconButton aria-label="Crear Planificación" onClick={() => handleCrearPlanificacion() }>
                            <PostAddIcon titleAccess="Crear Planificación"/>
                        </IconButton>
                        )}
                    </Grid>
                </Grid>
                
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ComponenteManosDia 
                            key={diaActual.current.dia} 
                            dia={diaActual.current.dia} 
                            mes={diaActual.current.mes} 
                            anio={diaActual.current.anio} 
                            manos={datosManos}
                            actualizaDatos={actualizaDatos}
                            usuarioActual = {usuarioActual.current?.permisosApp}
                            />
                    </Grid>
                </Grid>
                </>

                )}
                {crearParte.idPlanificacion > 0 && (
                <CrearParte cerrarParte={cerrarParte} datosMano={crearParte}/>
                )}

                {crearPlanificacion && (
                    <CrearPlanificacion cerrarParte={cerrarPlanificacion} datosMano={crearParte}/>
                )}
                
            </Box>
        )}
      </Container>

    </React.Fragment>
    )
}
export default Operaciones

const ComponenteManosDia = ({dia, mes, anio, manos, actualizaDatos, usuarioActual}) => {
    
    const planificacion = (datosPlanificacion) => {        
        actualizaDatos(datosPlanificacion)
    }

    return(
        <div className="anchoColumnaPlanningSemanal"
        id={`listadoManos-${dia}`}
        >
        <div 
        className="titulo-navbar"
        id={`titulo-navbar-${dia}`}
        >
            {manos &&        
                manos
                .sort((a,b) =>
                (
                    a.ordenMano - b.ordenMano ||
                    a.ordenPlanificacon - b.ordenPlanificacon ||
                    a.horario.localeCompare(b.horario) )
                )
                .map((mano, index) => (
                    <ComponenteMano key={index} mano={mano} planificacion={planificacion} usuarioActual={usuarioActual}/>
                )
            )}
        </div>
        </div>
    )   
}

const ComponenteMano = ({mano, planificacion, usuarioActual}) => {   
    
    const [expanded, setExpanded] = useState('');
    const [abrirDialogo, setAbrirDialogo] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 
    const [openAlert,setOpenAlert] = useState({
        open : false,
        tipoMensaje : "success",
        mensaje : ""
      }); 

    const planificacionEliminar = useRef(-1);
    const classes = useStyles();
    const date = new Date();
    const numeroDiaMes = date.getDate()
    const mesActual = date.getMonth();
    const anioActual = date.getFullYear();

    let esHoy = false;
    
    if(numeroDiaMes === mano.diaMes && mesActual === mano.mes && parseInt(anioActual) === parseInt(mano.anio)){
        esHoy = true
    }
    
    const crearParte = (datosMano) => {
        planificacion(datosMano)
    }

    const eliminarPlanificacion = (datosMano) => {        
        planificacionEliminar.current = datosMano.idPlanificacion  
        setAbrirDialogo(true) 
    }

    const verParte = (parte) => {

        const fetchData = async () => {
            const URL_DATOS = process.env.REACT_APP_API_URL + "operacionesapps/generapartepdf/" + parte.Id;
               
            axios.get(URL_DATOS, {})
                .then(({ data }) => {                    
                    const oDate = new Date();
                    window.open(data +'?qt='+oDate.getTime(), "_blank")
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                 })
                 .finally(function() {
             
                 });
        };
        fetchData(); 

    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleCerrarDialogo = (valor) => {       
    setAbrirDialogo(false)
    if(valor){    
      const fetchData = async () => {  
        setIsLoading(true)
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + 'planificacionesapps';
        await axios
        .delete(SERVER_URL + "/" + planificacionEliminar.current, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },        
        })
        .then((response) => {            
          if(!isNaN(response.data)){ 
            if(parseInt(response.data) === parseInt(planificacionEliminar.current)){
              planificacionEliminar.current = -1 
              setIsLoading(false)
              window.location.reload();
            }          
          } else{            
            //alert(response.data);  
            setOpenAlert({
                open : true,
                tipoMensaje : "error",
                mensaje : response.data
            })
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.log("error " + error);
          setIsLoading(false)
        });  
      }
      fetchData();
    }      
    }

    const handleCloseAlert = (event, reason)=>{
        if(reason === "clickaway"){
          return
        }
        setOpenAlert({...openAlert,open:false});
      }
    
    return(
        <div 
        className={ `${classes.root} ${ esHoy ? classes.fondoDiaActual : ""} }` }
        id={`mano-${mano.idPlanificacion}-${mano.idMano}`}
        >
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={ `${classes.accordion} ${mano.numPartes === 0 ? classes.bordeSinPartes : classes.bordeConPartes} ` }>
                
                <AccordionSummary
                    expandIcon={expanded === 'panel1'?<RemoveIcon />:<AddIcon/>}
                    aria-controls="panel1a-content"
                    id1="panel1a-header"
                    className={classes.accordionSummary}
                    id={`draggable-accordion${mano.idPlanificacion}-${mano.idMano}`}
                    >
                    <div className={classes.cabecera2}>
                        <Typography className={classes.horario} color="textSecondary">
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.codigo}</span>
                        </Typography>
                        <Typography className={classes.horario} color="textSecondary">
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.horario}</span>
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary" >
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.operativa}</span>
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary" >
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.buque1}</span>
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary" >
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.localizacion1}</span>
                        </Typography>
                    </div>
                </AccordionSummary>

                <AccordionDetails className="d-block" >
                    <div className={classes.pie}>
                        { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar la planificación " + mano.codigo + "?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
                        
                        {mano.clientes &&        
                            mano.clientes.map((cliente, index) => (
                                <Typography  key={index} className={classes.otrosTextos} color="textSecondary" >
                                <ArrowRightIcon /><span className={classes.marginIconText}>{cliente}</span>
                                </Typography>
                            ))
                        }

                        <Typography className={classes.horario} color="textSecondary" >
                            <ArrowRightIcon style={{ color: 'red' }}/><span className={classes.marginIconText}>{mano.supervisor}</span>
                        </Typography>

                        {mano.empleados &&        
                            mano.empleados.map((empleado, index) => (
                                <Typography  key={index} className={classes.otrosTextos} color="textSecondary" >
                                <ArrowRightIcon /><span className={classes.marginIconText}>{empleado}</span>
                                </Typography>
                            ))
                        }
                        
                        {mano.descripcion &&
                        <Typography className={classes.otrosTextos} color="textSecondary">
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.descripcion}</span>
                        </Typography>
                        }
                        
                        {mano.partes && usuarioActual === "SUPERVISOR" &&        
                            mano.partes.map((parte, index) => (
                                <Typography  key={index} className={classes.otrosTextos} style={{textAlign: 'center', marginTop: 10}} color="textSecondary" >
                                <Button startIcon={<PictureAsPdfIcon />} style={{ width: '75%', padding: 0}} variant="outlined" onClick={() => verParte(parte) } color="primary">Ver Parte {parte.Id}</Button>
                                </Typography>
                            ))
                        }

                        <Typography className={classes.enlacePlanificacion} color="textSecondary">
                            <Button style={{width: '100%'}} variant="contained" onClick={() => crearParte({idPlanificacion: mano.idPlanificacion, codigo: mano.codigo, idOperacion: mano.idOperacion, idMano: mano.idMano}) } color="primary">Crear Parte</Button>
                        </Typography>                        

                        {usuarioActual === "SUPERVISOR" && (
                            <Typography className={classes.enlacePlanificacion} color="textSecondary">
                            <Button style={{width: '100%'}} variant="contained" onClick={() => eliminarPlanificacion({idPlanificacion: mano.idPlanificacion, codigo: mano.codigo, idOperacion: mano.idOperacion, idMano: mano.idMano}) } color="secondary">Eliminar Planificación</Button>
                            </Typography>
                        )}

                        {openAlert.open && (
                            <AlertaMensaje
                                mensaje={openAlert.mensaje} 
                                isOpen={openAlert.open} 
                                tipoMensaje={openAlert.tipoMensaje}
                                cerrar={handleCloseAlert}
                            />       
                        )}

                    </div>
                </AccordionDetails>

            </Accordion>
        </div>
    )
}