import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TextField, Button, Container, CssBaseline, Box, Avatar, Typography, Grid } from "@mui/material";
import { connect } from "react-redux";
import { recoveryPassword, resetError } from "../../actions/auth";
import MuiAlert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default connect(({ reducerApi }) => ({ reducerApi }), {
  recoveryPassword,resetError
})((props) => {  

  const defaultTheme = createTheme();  
  const [error, setError] = useState("");
  const [mensaje, setMensaje] = useState("");

  useEffect(() => {  
    setError(null)
    setMensaje(null)
    props.resetError()
    return () => {
      setError(null)
      setMensaje(null)
      props.resetError()
    }
  }, [])

  useEffect(() => {
    setError(props.reducerApi.error)
    setMensaje(props.reducerApi.mensaje)
    return () => {
      setError(null)
      setMensaje(null)
    }
  }, [props.reducerApi])


  const ValidateEmail = (input) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if(input.match(validRegex)){
      return true
    }
    else return false
  }

  const HandleSubmit = (event) => {
    setError(null)
    setMensaje(null)
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    if(ValidateEmail(email)){
      props.recoveryPassword({ email });
    }   
  };

  return (
  <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Avatar sx={{ width: 96, height: 96, m: 1, bgcolor: 'secondary.main' }}>
            <img src="/logo192.png" alt="Logo" width="96" height="96" />
          </Avatar>

          <Typography component="h1" variant="h5">
            RECUPERAR CONTRASEÑA
          </Typography>

          <Box component="form" onSubmit={HandleSubmit} noValidate sx={{ mt: 1 }}>

          <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              type={"email"}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Recuperar
            </Button>

            <Grid container>
              <Grid item xs>
                <Link to={"/login"}>
                  Acceder
                </Link>
              </Grid>

              <Grid item xs>
                {error && (
                  <Alert severity="error" onClick={() => setError(null)}>{error}</Alert>
                )}
              </Grid>    
              
              <Grid item>
                {mensaje && (
                  <Alert severity="info" onClick={() => setMensaje(null)}>{mensaje}</Alert>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                {props.reducerApi.isLoading &&
                  <Typography variant="caption" display="block" gutterBottom>Comprobando email...</Typography>}
                  </Grid>              
            </Grid>


          </Box>
        </Box>
      </Container>
  </ThemeProvider>
  )
});
