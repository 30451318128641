import { apiRequest, apiResetError } from "../actions/api";
import { LOGIN, RECOVERYPASS, RESETERROR } from "../actions/auth";

const SERVER_URL = `${process.env.REACT_APP_API_URL_ROOT}userapps`;

export const appMiddleware = () => (next) => (action) => {
  next(action);
  switch (action.type) {
    case LOGIN: {
      next(
        apiRequest({
          url: `${SERVER_URL}/loginapp`, //?username=${action.payload.username}&password=${action.payload.password}`,
          method: 'post',
          data: action.payload,
        })
      );
      break;
    }
    case RECOVERYPASS: {
      next(
        apiRequest({
          url: `${SERVER_URL}/recoverypassapp`, //?email=${action.payload.email}`,
          method: 'post',
          data: action.payload,
        })
      );
      break;
    }

    case RESETERROR: {
      next(
        apiResetError()
      )
    }
    default:
      break;
  }
};
