import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ 
  isAuthenticated,
  redirectPath = '/login',
  children, }) => {
    
    if (!isAuthenticated) 
      return <Navigate to={redirectPath} replace />;

    if (isAuthenticated)
      return <Navigate to={"/"} replace />;
  };

  export default ProtectedRoute;