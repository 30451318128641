import React, { useEffect, useRef, useState } from "react";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Autocomplete, Box, Chip, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, CircularProgress } from "@mui/material";
import { styled } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Typography from '@mui/material/Typography';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import FormFieldFiles from './FormFieldFiles'

import SignatureCanvas from 'react-signature-canvas'
import axios from "axios";
import LoadingComponent from "./LoadingComponent";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormularioParte = ({cerrarParte, datosMano, modo}) => {
    
    const [open, setOpen] = useState(false);
    const [valores, setValores] = useState([]);
    const [openErrores, setOpenErrores] = useState({mostrar: false, mensaje: ""});
    const [ficheroAEliminar, setFicheroAEliminar] = useState({});
    const [ficheros, setFicheros] = useState([]);
    const [nombresFicheros, setNombresFicheros] = React.useState([]);
    const [datos, setDatos] = useState(
        {
            horaInicio: "00:00", 
            horaFin: "00:00", 
            empleados: [], 
            toneladas: 0,
            movimientos: 0,
            observaciones: "",
            nombreFirmante: "",
            dniFirmante: "",
            ficheros: []
    });
    const [value, setValue] = React.useState("");
    const sigCanvas = useRef();
    const [loading, setLoading] = useState(false);

    
    useEffect(() => { 
        let active = true;

        (async () => {   
            const respEmpleados = await axios(process.env.REACT_APP_API_URL + "userapps/listadoempleadosoperativas");      
            if (active) {


                if(respEmpleados.data.length > 0 ){
                  setValores(Object.keys(respEmpleados.data).map((key) => respEmpleados.data[key]))
                } else {
                    const etiqueta = 'id';
                    const sinDatos = [{ 
                        id: -1,
                        [etiqueta]: "Sin datos"
                    }]
                    setValores(sinDatos)
                }

                if(modo==="editar"){
                    const respParteGesport = await axios(process.env.REACT_APP_API_URL + "operacionesapps/parte/" + datosMano.idParte);      
                    setDatos({
                        horaInicio: respParteGesport.data.horaInicio, 
                        horaFin: respParteGesport.data.horaFin, 
                        empleados: respParteGesport.data.empleados, 
                        toneladas: parseFloat(respParteGesport.data.Toneladas),
                        movimientos: parseFloat(respParteGesport.data.Movimientos),
                        observaciones: respParteGesport.data.Observaciones,
                        nombreFirmante: respParteGesport.data.nombreFirmante || "",
                        dniFirmante: respParteGesport.data.dniFirmante || "",
                        ficheros: respParteGesport.data.ficheros
                    });
                    
                    if (Object.keys(sigCanvas) && respParteGesport.data.FirmaCliente) {
                        sigCanvas.current.fromDataURL(respParteGesport.data.FirmaCliente);
                    }
                
                }
            }
          }
        )();
    
          return () => {
            active = false;
          };
    }, [])

    useEffect(() => {
        if (value) {
            sigCanvas.current.fromDataURL(value);
        }
     }, [value]);

    const guardarParte = async () => {
        
        let mensajeError = "";
        if(datos.horaInicio === "00:00" && datos.horaFin === "00:00") 
            mensajeError += "Debe introducir la hora de inicio y finalización correctamente.\n"

        if(datos.empleados.length === 0)
            mensajeError += "Debe seleccionar al menos un empleado.\n";

        if(parseFloat(datos.toneladas) + parseFloat(datos.movimientos) === 0)   
            mensajeError += "Debe introducir el número de movimientos o toneladas.\n";
        let URL = sigCanvas.current.toDataURL("image/png");
        
        if(sigCanvas.current.isEmpty()){
            URL = null;
            //mensajeError += "El cliente debe firmar el parte.\n";
        }

        if(datos.nombreFirmante.length === 0)
            mensajeError += "Debe introducir el nombre de la persona que firma el parte.\n";

        if(datos.dniFirmante.length === 0)
            mensajeError += "Debe introducir el DNI de la persona que firma el parte.\n";

        if(mensajeError.length > 0){
            setOpenErrores({mostrar: true, mensaje: mensajeError})
            return;
        }
        
        datos.firmaCliente = URL;
        datos.idPlanificacion = datosMano.idPlanificacion;
        datos.idMano = datosMano.idMano;
        datos.idOperacion = datosMano.idOperacion;
        datos.ficheros = ficheros
        datos.nombresFicheros = nombresFicheros

        const user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);

        datos.idUsuario = user.id       
        
        if(modo === "crear")
        {  
            setLoading(true)
            const SERVER_URL = `${process.env.REACT_APP_API_URL}operacionesapps/crearpartegesportempleados`;
            const options = {
                method: 'post',
                url: SERVER_URL,
                data: datos,
                headers: { 'content-type': 'multipart/form-data' }                
            };

            axios(options)
              .then((response) => { 
                
                if(!isNaN(response.data)){   
                    cerrarParte() 
                }else{                   
                    //alert(response)
                }            
              })
              .catch(function (error) {

                if (error.response) {
                    // La respuesta fue hecha y el servidor respondió con un código de estado
                    // que esta fuera del rango de 2xx
                    //alert(3)
                    alert(error.response.data);
                    alert(error.response.status);
                    alert(error.response.headers);
                  } else if (error.request) {
                    // La petición fue hecha pero no se recibió respuesta
                    // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
                    // http.ClientRequest en node.js
                    alert(4)
                    
                    alert(JSON.stringify(error.request));
                  } else {
                    //alert(5)
                    // Algo paso al preparar la petición que lanzo un Error
                    alert('Error', error.message);
                    console.log(5)
                    // Algo paso al preparar la petición que lanzo un Error
                    console.log('Error', error.message);
                  }
                  //alert(JSON.stringify(error.config));
                  //console.log(JSON.stringify(error.config));
                
              })
              .finally(function () {
                // siempre sera ejecutado
                setLoading(false)
              });
        }

        if(modo === "editar")
        {
            setLoading(true)
            const SERVER_URL = `${process.env.REACT_APP_API_URL}operacionesapps/editarpartegesportempleados`;
            datos.idParte = datosMano.idParte
            const options = {
                method: 'post',
                url: SERVER_URL,
                data: datos,
                headers: { 'content-type': 'multipart/form-data' }                
            };
            
            axios(options)
            .then((response) => {  
                
                if(!isNaN(response.data)){   
                    cerrarParte() 
                }else{
                }            
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    // La respuesta fue hecha y el servidor respondió con un código de estado
                    // que esta fuera del rango de 2xx
                    //alert(3)
                    alert(error.response.data);
                    alert(error.response.status);
                    alert(error.response.headers);
                  } else if (error.request) {
                    // La petición fue hecha pero no se recibió respuesta
                    // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
                    // http.ClientRequest en node.js
                    alert(4)
                    
                    alert(JSON.stringify(error.request));
                  } else {
                    //alert(5)
                    // Algo paso al preparar la petición que lanzo un Error
                    alert('Error', error.message);
                    console.log(5)
                    // Algo paso al preparar la petición que lanzo un Error
                    console.log('Error', error.message);
                  }
            })
            .finally(function () {
                // siempre sera ejecutado
                setLoading(false)
            });
        }

    }

    const limpiarFirma = () => {
        sigCanvas.current.clear();
    }

    const handleSelectedFile = ({target}) => {
        for (let i=0; i<target.files.length; i++){  
            const fileReader = new FileReader();          
            fileReader.readAsDataURL(target.files[0]);
            fileReader.onload = (e) => {
              setFicheros(ficheros => [...ficheros, e.target.result] )
              setNombresFicheros(nombresFicheros => [...nombresFicheros, target.files[i].name] )                                  
              
            };
          } 
    };

    const handleDelete = (index) => {  
        let elementos = JSON.parse(JSON.stringify(ficheros)); 
        let elementos2 = JSON.parse(JSON.stringify(nombresFicheros));       
        elementos.splice(index, 1)
        elementos2.splice(index, 1)
        setFicheros(elementos)
        setNombresFicheros(elementos2) 
    }  

    const handleCloseEliminar = () => {      
        const ficherosFiltrados = datos.ficheros.filter((file) => file.name !== ficheroAEliminar.name);
        setDatos({...datos, ficheros: ficherosFiltrados});
        setOpen(false);
      };

    const handleCloseCancelar = () => {      
        setOpen(false);
    };

    const handleCloseCancelarErrores = () => {      
        setOpenErrores({mostrar: false, mensaje: ''})
    };

    const codigo = datosMano?.codigo;

    const element = {
            required: false,
            label: "",
            name: "ficherosremotos",
            type: "files", 
            ficheros: datos.ficheros,    
            tabla: "mtms_operaciones_gesport_adjuntos",
            modelo: "api\\modules\\v1\\models\\operaciones\\OperacionesGesportAdjuntos",
            carpeta: "operaciones/partesgesport",
            xs:4,
            cerrarParte: cerrarParte
    }
    const textoLoading = modo==="editar" ? "...ACTUALIZANDO PARTE" : "...CREANDO PARTE";

    return (
        <>
        {loading && (
             <LoadingComponent texto={textoLoading} />
        )}
        {!loading && (
        <Box>
            <Container maxWidth="sm">
                
                <Grid style={{marginTop: '0px'}} container spacing={2}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography 
                            variant="caption" 
                            display="block" 
                            gutterBottom
                            style={{textAlign: 'right'}}
                        >
                            {codigo}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{paddingTop: '0px'}}>
                        <hr style={{
                            color: "#000",
                            backgroundColor: "#000",
                            height: 1
                        }}/>
                    </Grid>
                </Grid>

                <Grid style={{marginTop: '20px'}} container spacing={2}>
                    {/* HORA INICIO */}
                    <Grid item xs={6}>
                        <TextField
                            label="Hora Inicio"
                            id="horaInicio"                            
                            size="small"
                            type="time"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={datos.horaInicio}
                            onChange={(event) => {
                                setDatos({...datos, horaInicio: event.target.value});
                            }}
                        />                
                    </Grid>
                    {/* HORA FIN */}
                    <Grid item xs={6}>
                        <TextField
                            label="Hora Fin"
                            id="horaFin"                            
                            size="small"
                            type="time"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={datos.horaFin}
                            onChange={(event) => {
                                setDatos({...datos, horaFin: event.target.value});
                            }}
                        />                
                    </Grid>
                    {/* EMPLEADOS */}
                    <Grid item xs={12}>
                        <Autocomplete
                            value={datos.empleados}
                            onChange={(event, newValue) => {
                                setDatos({...datos, empleados: newValue});
                            }}
                            multiple
                            id="empleados"
                            options={valores}
                            disableCloseOnSelect
                            size="small"
                            getOptionLabel={(option) => option.nombre}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.nombre}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Empleados" placeholder="" />
                            )}
                        />

                    </Grid>
                    {/* HORA TONELADAS */}
                    <Grid item xs={6}>
                        <TextField
                            label="Toneladas"
                            id="toneladas"                            
                            size="small"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            value={datos.toneladas}
                            onChange={(event) => {
                                setDatos({...datos, toneladas: event.target.value});
                            }}
                        />                
                    </Grid>
                    
                    {/* MOVIMIENTOS */}
                    <Grid item xs={6}>
                        <TextField
                            label="Movimientos"
                            id="movimientos"                            
                            size="small"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            value={datos.movimientos}
                            onChange={(event) => {
                                setDatos({...datos, movimientos: event.target.value});
                            }}
                        />                
                    </Grid>
                    
                    {/* OBSERVACIONES */}
                    <Grid item xs={12}>
                        <TextField
                            id="observaciones"
                            label="Observaciones"
                            multiline
                            rows={3}
                            style={{ width: '100%' }}
                            //defaultValue="Default Value"
                            value={datos.observaciones}
                            onChange={(event) => {
                                setDatos({...datos, observaciones: event.target.value});
                            }}
                        />
                    </Grid>
                    {/* NOMBRE FIRMANTE*/}
                    <Grid item xs={6} className="">
                        <TextField
                            id="nombreFirmante"
                            label="Nombre"                            
                            style={{ width: '100%' }}
                            size="small"
                            required
                            //defaultValue="Default Value"
                            value={datos.nombreFirmante}
                            onChange={(event) => {
                                setDatos({...datos, nombreFirmante: event.target.value});
                            }}
                        />
                    </Grid>
                    {/* DNI FIRMANTE*/}
                    <Grid item xs={6} className="">
                        <TextField
                            id="dniFirmante"
                            label="DNI"                            
                            style={{ width: '100%' }}
                            size="small"
                            required
                            //defaultValue="Default Value"
                            value={datos.dniFirmante}
                            onChange={(event) => {
                                setDatos({...datos, dniFirmante: event.target.value});
                            }}
                        />                        
                    </Grid>
                    {/* FIRMA CLIENTE */}
                    <Grid item xs={6} className="">
                        <Typography variant="caption" display="block" gutterBottom>
                            Firma cliente
                        </Typography>
                    </Grid>

                     {/* LIMPIAR FIRMA */}
                     <Grid item xs={6} className="">
                        <Button 
                            variant="contained" 
                            startIcon={<DeleteIcon />}
                            fullWidth
                            onClick={() => limpiarFirma() } 
                            color="primary"> 
                        </Button>
                    </Grid>

                    <Grid item xs={12} className="sigPadContainer">
                        <SignatureCanvas 
                            penColor="black" 
                            canvasProps={{ className: "sigCanvas" }} 
                            ref={sigCanvas}
                            willReadFrequently={true}
                        />
                    </Grid>

                    {/* FICHEROS REMOTOS */}
                    <FormFieldFiles
                        propiedades={element}
                        //control={control} 
                        ficheros={handleSelectedFile}
                    />
                   
                    {/* ADJUNTAR FICHERO */}
                    <Grid item xs={12} className="">
                        <Button
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            fullWidth
                           component="label"
                        >
                            <VisuallyHiddenInput multiple type="file" accept="image/*" onChange={handleSelectedFile} />
                        </Button>

                    </Grid>

                    {/* FICHEROS ADJUNTOS */}
                    <Grid item xs={12}>
                    {nombresFicheros && 
                     nombresFicheros.map((option, index) => (
                        <MenuItem key={index} value={option} className="no-gutters float-left">
                            <Grid item xs={12}>
                                <Chip 
                                    key={index}
                                    className="m-1"
                                    variant="outlined" 
                                    color="primary" 
                                    size="small" 
                                    onDelete={() => handleDelete(index)} 
                                    //onClick={() => handleClick(option)}
                                    label={option}
                                />
                            </Grid>
                        </MenuItem>
                     ))}
                    </Grid>
                </Grid>

                <Grid style={{marginTop: '0px'}} container spacing={4}>
                    <Grid item xs={12}>
                        {/* DIALOGO ELIMINAR FICHEROS ADJUNTOS */}
                        <Dialog 
                            open={open}
                            //TransitionComponent={Transition}
                            keepMounted
                            onClose={() => handleCloseCancelar()}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{"¿Desea eliminar el fichero?"}</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleCloseEliminar} color="primary">
                                    Eliminar
                                </Button>
                                <Button onClick={handleCloseCancelar} color="primary">
                                    Cancelar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>

                <Grid style={{marginBottom: '80px'}} container spacing={4}>
                    <Grid item xs={6}>
                        <Button 
                            variant="contained" 
                            startIcon={<SaveIcon />}
                            fullWidth
                            onClick={() => guardarParte() } 
                            color="primary"></Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            variant="contained" 
                            startIcon={<CloseIcon />}
                            fullWidth
                            onClick={() => cerrarParte() } 
                            color="primary">
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {/* DIALOGO MOSTAR ERRORES */}
                        <Dialog 
                            open={openErrores.mostrar}
                            //TransitionComponent={Transition}
                            keepMounted
                            onClose={() => handleCloseCancelar()}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{"ERROR"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" style={{whiteSpace: 'pre-line'}}>
                                    {openErrores.mensaje}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseCancelarErrores} color="primary">
                                    Cerrar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        )}        
        </>
    )
}
export default FormularioParte

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});