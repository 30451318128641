import React from "react";
import { Outlet } from "react-router-dom";

const Main = (props) => { 
  return (
    <>
    <div style={{maxHeight: "99vh", minHeight: "99vh"}}> 
      <div className="row min-vh-90 ml-0 mr-0">
      <Outlet />
      </div>
    </div>
    </>
  );
};
export default Main;