import React, { useEffect, useState } from "react";
import { Box, Button, Container, CssBaseline, Grid, Typography } from "@mui/material";
import { TreeView } from "@mui/x-tree-view";
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import {  styled, useTheme } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';
import HealingIcon from '@mui/icons-material/Healing';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import axios from "axios";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


const Perfil = ({props}) => {
    const [tipoDocumentacion, setTipoDocumentacion] = useState("1")
    const [documentacion, setDocumentacion] = useState([])
    
    const fetchData = () => {
        (async () => {
            const user = await JSON.parse(localStorage.getItem("user"));
            let url = "";

            if(parseInt(tipoDocumentacion) === 1 ) url = "userapps/rrhhdatosformacion";
            if(parseInt(tipoDocumentacion) === 2 ) url = "userapps/rrhhdatosseguridadysalud";
            if(parseInt(tipoDocumentacion) === 3 ) url = "userapps/rrhhdatosempleado";
          
            const respDocumentacion = await axios(
              `${process.env.REACT_APP_API_URL}${url}/${user.id}`
            );  
            setDocumentacion(respDocumentacion.data)
        })();
    }

    useEffect(() => { 
        fetchData()
    }, [tipoDocumentacion])

    const handleLogOut = (e) => {
        props.logout()
        window.location.href = '/';
    }
    const actualHeight=0;
    
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="xl">
                <Box sx={{ bgcolor: '#FFF', height: actualHeight }}>

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography color="textSecondary"><span>Documentación</span></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <hr></hr>
                    <TreeView
                        aria-label="customized"
                        defaultExpanded={['3']}
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultEndIcon={<div style={{ width: 24 }} />}
                        sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                        selected={tipoDocumentacion}
                    >   
                        <StyledTreeItem nodeId="1" labelText="Formación" labelIcon={SchoolIcon} 
                        onClick={() => setTipoDocumentacion("1")}/>
                        <StyledTreeItem nodeId="2" labelText="Seguridad y Salud" labelIcon={HealingIcon}
                        onClick={() => setTipoDocumentacion("2")} />
                        <StyledTreeItem nodeId="3" labelText="Contrato y Nóminas" labelIcon={ReceiptLongIcon}
                        onClick={() => setTipoDocumentacion("3")} />
                        
                        
                    </TreeView>                    
                    </Grid>
                    <Grid item xs={12}>
                        <Documentos documentacion={documentacion} />
                    </Grid>
                    
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button fullWidth variant="contained" onClick={(e) => handleLogOut(e) } color="primary">Cerrar sesión</Button>  
                    </Grid>
                </Grid>
    
                </Box>
            </Container>
        </React.Fragment>   
        )    
}
export default Perfil


const Documentos = ({ documentacion }) => {
    
    const verFichero = (fichero) => {
      const oDate = new Date();
      if(fichero){
          if(fichero.Ubicacion){
              window.open(fichero.Ubicacion +'?qt='+oDate.getTime(), "_blank")
          }
      }
    }

    return(
      <>
      {documentacion &&        
        documentacion.map((fichero, index) => (
            <Typography  key={index} style={{ marginBottom: 10,  overflowWrap: 'anywhere'}} color="textSecondary" >
            <Button style={{ width: '100%', padding: 8, overflow: 'hidden', textAlign: 'left'}} variant="outlined" onClick={() => verFichero(fichero) } color="primary">{fichero.Nombre}</Button>
            </Typography>
        ))
      }

      {documentacion.length === 0 && 
        <Typography  style={{ marginBottom: 20,  textAlign: 'center'}} color="textSecondary" >
          NO HAY DOCUMENTACIÓN
        </Typography>
      }
      </>
    )
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '&.Mui-expanded': {
        fontWeight: theme.typography.fontWeightRegular,
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: 'var(--tree-view-color)',
      },
      [`& .${treeItemClasses.label}`]: {
        fontWeight: 'inherit',
        color: 'inherit',
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 0,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(2),
      },
    },
}));
  
const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
    const theme = useTheme();
    const {
      bgColor,
      color,
      labelIcon: LabelIcon,
      labelInfo,
      labelText,
      colorForDarkMode,
      bgColorForDarkMode,
      ...other
    } = props;
  
    const styleProps = {
      '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
      '--tree-view-bg-color':
        theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };
  
    return (
      <StyledTreeItemRoot
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 0.5,
              pr: 0,
            }}
          >
            <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
            <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </Box>
        }
        style={styleProps}
        {...other}
        ref={ref}
      />
    );
});

