import { SET_LOADER, SET_MODE } from "../actions/ui";
import { API_SUCCESS, API_ERROR, PASSWORD_RECOVERY_SEND, RESET_ERROR} from "../actions/api";
import { LOGOUT } from "../actions/auth";

/*
let user = localStorage.getItem("user");
console.log(user, !!localStorage.getItem("user"))

if (user === "undefined") {
  localStorage.removeItem("user");
}
console.log(JSON.parse(localStorage.getItem("user")));
localStorage.removeItem("user");
*/

const reducerApi = (
  state = {
    isAuthUser: !!localStorage.getItem("user"),
    user: JSON.parse(localStorage.getItem("user")) || {},
    isLoading: false,
    error: null,
    mensaje: null,
    maintenanceMode: false
  },
  action
) => {
  switch (action.type) {
    case API_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        isAuthUser: true,
        user: action.payload,
        isLoading: false,
      };
    case API_ERROR:
      return { ...state, error: action.payload, isLoading: false, mensaje: null };
    case SET_LOADER:
      return { ...state, isLoading: action.payload };
    case SET_MODE:
        return { ...state, maintenanceMode: action.payload };
    case LOGOUT:
      localStorage.removeItem("user");
      return { ...state, isAuthUser: false, user: {} };
    case PASSWORD_RECOVERY_SEND:
        return { ...state, mensaje: action.payload, isLoading: false, error: null };
    case RESET_ERROR:
      return { ...state, mensaje: action.payload, isLoading: false, error: null };
    default:
      return state;
  }
};

export default reducerApi;
