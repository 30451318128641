export const SET_LOADER = "SET_LOADER";
export const SET_MODE = "SET_MODE";

export const setLoader = ({ state }) => ({
  type: SET_LOADER,
  payload: state,
});

export const setMode = ({ state }) => ({
  type: SET_MODE,
  payload: state,
});
