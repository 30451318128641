import React, { useEffect, useState } from "react";
import { TextField, Button, InputAdornment, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { login, resetError } from "../../actions/auth";
import MuiAlert from '@mui/material/Alert';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";

import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default connect(({ reducerApi }) => ({ reducerApi }), {
  login, resetError
})((props) => {
  
  const [error, setError] = useState("");
  const defaultTheme = createTheme();  

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {  
    setError(null)
    props.resetError()
    return () => {
      setError(null)
      props.resetError()
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('user');
    const password = data.get('password');

    if (username === "" || password === "") {
      setError("Debe rellenar ambos campos");
      return;
    }    

    props.login({ username, password });    
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ width: 96, height: 96, m: 1, bgcolor: 'secondary.main' }}>
            <img src="/logo192.png" alt="Logo" width="96" height="96" />
          </Avatar>

          <Typography component="h1" variant="h5">
            ACCESO
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              label="Usuario"
              name="user"
              autoComplete="usuario"
              autoFocus
            />
            {/*
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            */}
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              //onChange={someChangeHandler}
              InputProps={{ // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </Button>            
            <Grid container>
              <Grid item xs>
                <Link to={"/recuperarpassword"}>
                  Recuperar contraseña
                </Link>
              </Grid>
              <Grid item>
                
              </Grid>
            </Grid>
          </Box>

          <Grid container>
              <Grid item xs>
                {props.reducerApi.error && (
                  <Alert severity="error" onClick={() => {
                      setError(null)
                      props.resetError()
                      } 
                    }>
                    {props.reducerApi.error}
                  </Alert>
                )}

                {error && (
                  <Alert severity="error" onClick={() => {
                    setError(null)
                    props.resetError()
                  }
                }>
                    {error}
                  </Alert>
                )}
                </Grid>              
            </Grid>

          <Grid container>
              <Grid item xs>
              <Typography variant="caption" display="block" gutterBottom>{props.reducerApi.error?.message}</Typography>
                {props.reducerApi.isLoading && (
                  <Typography variant="caption" display="block" gutterBottom>Comprobando acceso...</Typography>
                )}
              </Grid>              
            </Grid>
            
        </Box>

      </Container>
    </ThemeProvider>
  )
});
