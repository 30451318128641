import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { makeStyles } from "mui-styles";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, CssBaseline, FormControl, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditarParte from "./EditarParte";

import SearchIcon from '@mui/icons-material/Search';
import LoadingComponent from "./LoadingComponent";

const INTERVAL_TIME = 1000 * 60 * 100; // 5 Minutos 1000 * 60 * 5

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        '& > *': {
        //margin: theme.spacing(1),
        width: theme.spacing(25),
        //minHeight: theme.spacing(25),
        borderRadius: '15px !important',
        //padding: theme.spacing(1),
        },
        padding:10,
    },
    accordion: {
        minHeight: '130px !important;',
        paddingTop: '20px !important;',
        /*padding: '-1px -1px !important;',*/
        width: '100% !important',
    },
    accordionSummary: {
        marginBottom: '0px !important',
        padding: '0px 10px !important',
        minHeight: '90px !important',
    },    
    pie: {
        marginTop: 10,
    },
    diaMes: {
        fontFamily: 'Open Sans',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
        color: '#3465A4'
    },
    horario: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        color: '#000'
    },
    otrosTextos: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '20px',
    },
    enlacePlanificacion: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '20px',
        marginTop: '20px',
        marginLeft: 20,
    },
    fondoDiaActual:
    {
        backgroundColor: "#EBEBEB",
    },    
    bordeSinFirmar: {
        borderColor: "green",
        borderStyle: 'groove',
    },    
    bordeFirmado: {
        borderColor: "#FF0000",
        borderStyle: 'groove',
    },
    marginIconText: {
        display: 'block',
        marginTop: -28,
        marginLeft: 22
    }
}));

const Partes = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [datosPartes, setDatosPartes] = useState([]); 
    const [datosPartesTotales, setDatosPartesTotales] = useState([]); 
    const [counter, setCounter] = useState(new Date());
    const [editarParte, setEditarParte] = useState({idPlanificacion: -1, cogigo: '', idOperacion: null, idMano: null, idParte: null})
    const classes = useStyles();
    const datosBusqueda = useRef({cop: '', firmado: false});

    const getData = () => {
        (async () => {            
            const user = await JSON.parse(localStorage.getItem("user"));
            if(user){
                setIsLoading(true)
                const URL_DATOS = process.env.REACT_APP_API_URL + `operacionesapps/partes/${user.id}`
                axios.get(URL_DATOS, {})
                .then(({ data }) => {                    
                    if(Array.isArray(data) && data.length > 0){
                        setDatosPartesTotales(data)
                    } else setDatosPartesTotales([])
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                 })
                 .finally(function() {
                    setIsLoading(false)
                 });
            }            
          })();
    }

    useEffect(() => {
        getData();
        setCounter(new Date());
    },[])

    useEffect(() => {
        let intervalId;
        intervalId = setInterval(() => {
            getData();
            setCounter(new Date());
          }, INTERVAL_TIME)
        
        return () => clearInterval(intervalId);
    }, [counter])

    useEffect(() => {
        if(datosBusqueda.current){
            const datosFiltrados = datosPartesTotales.filter((el) => el.firmado === datosBusqueda.current.firmado);
            setDatosPartes(datosFiltrados)
        }
    }, [datosPartesTotales])

    const actualizaDatos = async (datosParte) => {
        setEditarParte(datosParte)
    }

    const cerrarParte = () => {        
        datosBusqueda.current = { cop: '', firmado: false}
        setEditarParte({idPlanificacion: -1, cogigo: '', idOperacion: null, idMano: null, idParte: null})
        getData();
        setCounter(new Date());
    }

    const copABuscar = (cop) => {
        datosBusqueda.current = {...datosBusqueda.current, cop: cop.toLowerCase()}
        const datosFiltrados = datosPartesTotales.filter((el) => el.codigo.toLowerCase().includes(datosBusqueda.current.cop) && el.firmado === datosBusqueda.current.firmado);
        setDatosPartes(datosFiltrados)
    }

    const estadoABuscar = (estado) => {
        datosBusqueda.current = {...datosBusqueda.current, firmado: estado}
        const datosFiltrados = datosPartesTotales.filter((el) => el.codigo.toLowerCase().includes(datosBusqueda.current.cop) && el.firmado === datosBusqueda.current.firmado);
        setDatosPartes(datosFiltrados)
    }

    const numeroManos = datosPartes.length
    const actualHeight = `{${60*numeroManos}vh}`

    return (
    <React.Fragment>
        <CssBaseline />

        <Container maxWidth="xl">
        {isLoading && (
            <LoadingComponent />
        )}    
        {!isLoading && (
            <Box sx={{ bgcolor: '#FFF', height: actualHeight }}>
                <Grid container spacing={2}>
                <Grid item xs={6}><CustomizedInputBase copABuscar={copABuscar}/></Grid>
                <Grid item xs={6}><BasicSelect filtroEstado={ estadoABuscar } /></Grid>
                </Grid>

                {editarParte.idPlanificacion < 0 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        {datosPartes && 
                            datosPartes
                            .sort((a,b) =>
                            (
                                a.firmado - b.firmado ||
                                a.horario.localeCompare(b.horario) )
                            )
                            .map((mano, index) => (
                                <ComponenteMano key={index} mano={mano} actualizaDatos={actualizaDatos}/>
                            )
                        )}
                        </Grid>
                    </Grid>
                )}

                {editarParte.idPlanificacion > 0 && (
                    <EditarParte cerrarParte={cerrarParte} datosMano={editarParte}/>
                )}

            </Box>
        )}

        </Container>

    </React.Fragment>
    )
}
export default Partes

const ComponenteMano = ({mano, actualizaDatos}) => {

    const [expanded, setExpanded] = useState('');
    const classes = useStyles();
    
    const editarParte = (datosMano) => { 
        actualizaDatos(datosMano)
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    
    return(
        <div 
        className={ `${classes.root}` }
        id={`mano-${mano.idPlanificacion}-${mano.idMano}`}
        >
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={ `${classes.accordion} ${mano.firmado === true ? classes.bordeSinFirmar : classes.bordeFirmado} ` }>
                
                <AccordionSummary
                    expandIcon={expanded === 'panel1'?<RemoveIcon />:<AddIcon/>}
                    aria-controls="panel1a-content"
                    id1="panel1a-header"
                    className={classes.accordionSummary}
                    id={`draggable-accordion${mano.idPlanificacion}-${mano.idMano}`}
                    >
                    <div className={classes.cabecera2}>
                        <Typography className={classes.horario} color="textSecondary">
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.codigo}</span>
                        </Typography>
                        <Typography className={classes.horario} color="textSecondary">
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.horario}</span>
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary" >
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.operativa}</span>
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary" >
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.buque1}</span>
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary" >
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.localizacion1}</span>
                        </Typography>
                    </div>
                </AccordionSummary>

                <AccordionDetails className="d-block" >
                    <div className={classes.pie}>

                        {mano.clientes &&        
                            mano.clientes.map((cliente, index) => (
                                <Typography  key={index} className={classes.otrosTextos} color="textSecondary" >
                                <ArrowRightIcon /><span className={classes.marginIconText}>{cliente}</span>
                                </Typography>
                            ))
                        }
                        {/*
                        <Typography className={classes.horario} color="textSecondary" >
                            <ArrowRightIcon style={{ color: 'red' }}/><span className={classes.marginIconText}>{mano.supervisor}</span>
                        </Typography>
                        */}
                        {mano.empleados &&        
                            mano.empleados.map((empleado, index) => (
                                <Typography  key={index} className={classes.otrosTextos} color="textSecondary" >
                                <ArrowRightIcon /><span className={classes.marginIconText}>{empleado}</span>
                                </Typography>
                            ))
                        }
                        
                        {mano.descripcion &&
                        <Typography className={classes.otrosTextos} color="textSecondary">
                            <ArrowRightIcon /><span className={classes.marginIconText}>{mano.descripcion}</span>
                        </Typography>
                        }

                        <Typography className={classes.enlacePlanificacion} color="textSecondary">
                            <Button style={{width: '100%'}} variant="contained" onClick={() => editarParte({idPlanificacion: mano.idPlanificacion, codigo: mano.codigo, idOperacion: mano.idOperacion, idMano: mano.idMano, idParte: mano.idParte}) } color="primary">Editar Parte</Button>
                        </Typography>

                    </div>
                </AccordionDetails>

            </Accordion>
        </div>
    )
} 

const CustomizedInputBase = ({copABuscar}) => {
    const codigoCOP = useRef();

    const FiltraPorCOP = () => {
        copABuscar(codigoCOP.current)
    }

    const InputChange = (e) => {
        codigoCOP.current = e.target.value
    }

    return (
      <Paper
        component="form"
        sx={{ display: 'flex', alignItems: 'center', width: '100%', height: 40 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Buscar COP"
          inputProps={{ 'aria-label': 'buscar cop' }}
          onChange={(e) => { InputChange(e) } }
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => { FiltraPorCOP(); }}>
          <SearchIcon />
        </IconButton>
      </Paper>
    );
}

const BasicSelect = ({filtroEstado}) => {
    const [estado, setEstado] = useState(false);
  
    const handleChange = (event) => {
      setEstado(event.target.value);
      filtroEstado(event.target.value)
    };
  
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label">Estado</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={estado}
            label="Estado"
            onChange={handleChange}
          >
            <MenuItem value={false}>Pendientes</MenuItem>
            <MenuItem value={true}>Firmados</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
}